import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Masonry from "react-masonry-css";
import "./BpGalleryPage.scss";
import NineDots from '../component/NineDots'

const BpGalleryPage = () => {
  const location = useLocation(); // To access URL query params

  // Parse the query params
  const getCategoryFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get("category") || "makeup"; // Default to 'makeup'
  };

  const [activeTab, setActiveTab] = useState(getCategoryFromUrl());

  const images = {
    withClient: [
      "/assets/img/beauty-parlour/gallery/with_client/1.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/2.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/3.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/4.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/5.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/6.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/7.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/8.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/9.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/10.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/11.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/12.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/13.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/14.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/15.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/16.jpg",
      "/assets/img/beauty-parlour/gallery/with_client/17.jpg",
    ],
    events: [
      "/assets/img/beauty-parlour/gallery/events/1.jpg",
      "/assets/img/beauty-parlour/gallery/events/2.jpg",
      "/assets/img/beauty-parlour/gallery/events/3.jpg",
      "/assets/img/beauty-parlour/gallery/events/4.jpg",
      "/assets/img/beauty-parlour/gallery/events/5.jpg",
      "/assets/img/beauty-parlour/gallery/events/6.jpg",
      "/assets/img/beauty-parlour/gallery/events/7.jpg",
      "/assets/img/beauty-parlour/gallery/events/8.jpg",
      "/assets/img/beauty-parlour/gallery/events/9.jpg",
    ],
    makeup: [
      "/assets/img/beauty-parlour/gallery/makeup/1.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/2.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/3.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/83.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/4.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/5.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/6.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/7.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/8.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/9.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/10.jpg",     
      "/assets/img/beauty-parlour/gallery/makeup/12.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/13.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/84.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/14.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/15.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/16.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/17.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/18.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/19.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/20.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/21.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/22.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/23.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/24.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/25.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/26.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/28.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/29.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/30.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/31.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/32.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/34.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/35.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/36.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/37.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/38.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/39.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/40.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/41.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/42.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/43.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/44.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/45.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/46.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/47.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/48.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/49.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/50.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/51.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/52.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/53.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/54.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/55.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/56.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/57.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/58.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/59.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/60.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/60.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/61.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/62.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/63.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/64.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/65.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/66.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/67.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/68.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/69.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/70.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/71.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/72.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/73.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/74.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/75.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/76.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/77.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/78.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/79.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/80.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/81.jpg",
      "/assets/img/beauty-parlour/gallery/makeup/82.jpg", 
    ],
    hairdressing: [
      "/assets/img/beauty-parlour/gallery/hairdressing/1.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/2.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/3.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/4.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/5.jpg",
      //   '/assets/img/beauty-parlour/gallery/hairdressing/6.jpg',
      "/assets/img/beauty-parlour/gallery/hairdressing/7.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/8.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/9.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/10.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/11.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/12.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/13.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/14.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/15.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/16.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/17.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/18.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/19.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/20.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/21.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/22.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/23.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/24.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/25.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/26.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/28.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/29.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/30.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/31.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/32.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/34.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/35.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/36.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/37.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/38.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/39.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/40.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/41.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/42.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/43.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/44.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/45.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/46.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/47.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/48.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/49.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/50.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/51.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/52.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/53.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/54.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/55.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/56.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/57.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/58.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/59.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/60.jpg",

      "/assets/img/beauty-parlour/gallery/hairdressing/61.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/62.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/63.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/64.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/65.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/66.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/67.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/68.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/69.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/70.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/71.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/72.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/73.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/74.jpg",
      "/assets/img/beauty-parlour/gallery/hairdressing/75.jpg",
    ],
    nailart: [
      "/assets/img/beauty-parlour/gallery/nailart/1.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/2.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/3.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/4.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/5.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/6.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/7.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/8.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/9.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/10.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/11.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/12.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/13.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/14.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/15.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/16.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/17.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/18.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/19.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/20.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/21.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/22.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/23.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/24.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/25.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/26.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/28.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/29.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/30.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/31.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/32.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/34.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/35.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/36.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/37.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/38.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/39.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/40.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/41.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/42.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/43.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/44.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/45.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/46.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/47.jpg",
      "/assets/img/beauty-parlour/gallery/nailart/48.jpg",
    ],
  };

  // When URL changes, update active tab
  useEffect(() => {
    const newTab = getCategoryFromUrl();
    setActiveTab(newTab);
  }, [location.search]);

  // Handle tab switching
  const handleTabChange = (category) => {
    setActiveTab(category);
    // Update URL to reflect the selected category
    window.history.replaceState(null, null, `?category=${category}`);
  };

  // Breakpoint columns for masonry
  const breakpointColumnsObj = {
    default: 4,
    1199: 3,
    767: 2,
    575: 1,
  };

  return (
    <div className="gallery_page">
      <NineDots
  options={[
    { link: "", text: "Makeup", icon: "health_and_beauty", whenClick: () => handleTabChange("makeup") },
    { link: "", text: "Events", icon: "event", whenClick: () => handleTabChange("events")},
    { link: "", text: "Nail Art", icon: "touch_app" , whenClick: () => handleTabChange("nailart") },
    { link: "", text: "Hair Dressing", icon: "psychology" , whenClick: () => handleTabChange("hairdressing") },
    { link: "", text: "With Client", icon: "group" , whenClick: () => handleTabChange("withClient") },
  ]}
/>

      <div className="gallery_banner">
        <img src="./assets/img/beauty-parlour/blessy_gallery_banner.png" alt="" />
      </div>
      <div className="container">
        {/* Tabs for categories */}
        <div className="tabs">
        <button
            onClick={() => handleTabChange("makeup")}
            className={activeTab === "makeup" ? "active" : ""}
          >
            Makeup
          </button>
          <button
            onClick={() => handleTabChange("events")}
            className={activeTab === "events" ? "active" : ""}
          >
           Events
          </button>  
          <button
            onClick={() => handleTabChange("nailart")}
            className={activeTab === "nailart" ? "active" : ""}
          >
            Nail Art
          </button>
          <button
            onClick={() => handleTabChange("hairdressing")}
            className={activeTab === "hairdressing" ? "active" : ""}
          >
            Hair Dressing
          </button>
          <button
            onClick={() => handleTabChange("withClient")}
            className={activeTab === "withClient" ? "active" : ""}
          >
            With Client
          </button>
        </div>

        {/* Masonry gallery */}
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry-grid"
          columnClassName="masonry-grid_column"
        >
          {images[activeTab].map((image, index) => (
            <div key={index} className="masonry-item">
              <img src={image} className="masonry-image" />
            </div>
          ))}
        </Masonry>
      </div>
    </div>
  );
};

export default BpGalleryPage;
