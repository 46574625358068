import React from "react";
import { NavLink } from "react-router-dom"; // Import NavLink for active link handling
import "./BpHeader.scss";

const BpHeader = () => {
  return (
    <div className="bp_header">
      <div className="container">
        <div className="bp_h_inner">
          <div className="left">
            <NavLink to="/">
              <img src="./assets/img/beauty-parlour/logo_blessy3.png" alt="" />
            </NavLink>
          </div>
          <div className="middle">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "active home" : "home"
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/about-us"
              className={({ isActive }) =>
                isActive ? "active aboutus" : "aboutus"
              }
            >
              About Us
            </NavLink>
            <NavLink
              to="/services"
              className={({ isActive }) =>
                isActive ? "active services" : "services"
              }
            >
              Services
            </NavLink>
            <a
              href="#testimonial"
              className="testimonial"
            >
              Testimonial
            </a>
            <NavLink
              to="/contact-us"
              className={({ isActive }) =>
                isActive ? "active contactus" : "contactus"
              }
            >
              Contact Us
            </NavLink>
          </div>
          <div className="right">
            <NavLink
              to="/gallery"
              className={({ isActive }) =>
                isActive ? "active theme_btn btn_fill" : "theme_btn btn_fill"
              }
            >
              Gallery
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BpHeader;
