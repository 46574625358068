import React from "react";
import { Link } from "react-router-dom";
import "./BpServices.scss";

const BpServices = ({ services }) => {
  return (
    <div className="bpservices sect_padding" id="services">
      <div className="container">
        <div className="about_us_inner">
          <div className="sect_header text-center">
            <h2 className="title">
              Our Services
              <img
                src="./assets/img/beauty-parlour/icon/title_icon.png"
                alt="Title Icon"
                className="title_icon"
              />
            </h2>
            <h6 className="desc">
              At Blessy Beauty Parlour, we offer a wide range of beauty services tailored to your unique needs. From haircuts and coloring to skincare and makeup, indulge in a personalized experience that enhances your natural beauty.
            </h6>
          </div>
          <div className="services">
            {services.map((service, index) => (
              <div key={index} className="services_single">
                <div className="icon">
                  <img
                    src={service.icon}
                    alt={service.title}
                    className="black_icon"
                  />
                </div>
                <div className="card_title">{service.title}</div>
                <div className="card_desc">{service.description}</div>
                <Link to={service.link} className="theme_btn btn_fill">
                  Image
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BpServices;
