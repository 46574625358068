import React from "react";
import { Route, Routes } from "react-router-dom";

// import css

import "../css/Font.scss";
import "../css/Color.scss";
import "../css/Style.scss";

// import pages
import BpHomePage from "../pages/BpHomePage";
import BpHeader from "../component/BpHeader";
import BpGalleryPage from "../pages/BpGalleryPage";
import BpAboutusPage from "../pages/BpAboutusPage";
import BpContactUsPage from "../pages/BpContactUsPage";
import BpServicesPage from "../pages/BpServicesPage";
import BottomButton from "../component/BottomButton";
import DdBy from "../component/DdBy";

const BeautyParlourRoutes = () => {
  return (
    <div className="beauty-parlour">
      <BpHeader />
      <Routes>
        <Route path="/" element={<BpHomePage />}></Route>
        <Route path="/gallery" element={<BpGalleryPage />}></Route>
        <Route path="/about-us" element={<BpAboutusPage />}></Route>
        <Route path="/services" element={<BpServicesPage />}></Route>
        <Route path="/contact-us" element={<BpContactUsPage />}></Route>
        
      </Routes>
      <DdBy/>
      <BottomButton/>
    </div>
  );
};

export default BeautyParlourRoutes;
