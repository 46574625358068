import React from 'react'
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from '../templates/beautyParlour/component/ScrollToTop';
// import all templates 

import BeautyParlourRoutes from '../templates/beautyParlour/beautyParlourRoutes/BeautyParlourRoutes';



const Router = () => {
    return (
        <BrowserRouter>    
        <ScrollToTop/>       
            <BeautyParlourRoutes />      
            
        </BrowserRouter>
    )
}

export default Router
