import React from "react";
import { Link } from "react-router-dom";
import './BottomButton.scss'

const BottomButton = () => {
  return (
    <div className="bottombutton">
      <Link className="btn_single call"  to="tel:+918667359699">
        <div className="icon">
          <span class="material-symbols-outlined">call</span>
        </div>
        <h6>Call Now</h6>
      </Link>
      <Link className="btn_single photo" to="/gallery">
        <div className="icon">
          <span class="material-symbols-outlined">image</span>
        </div>
        <h6>Photos</h6>
      </Link>
    </div>
  );
};

export default BottomButton;
