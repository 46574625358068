import React from "react";
import { Link } from "react-router-dom";
import "./BpContactUs.scss";

const BpContactUs = () => {
  return (
    <div
      className="about_us contact_section sect_padding"
      style={{
        backgroundImage: "url('./assets/img/beauty-parlour/contact-us-bg.png')",
      }}
      id="contact-us"
    >
      <div className="container">
        <div className="about_us_inner">
          <div className="sect_header text-center">
            <h2 className="title">
              Contact Us
              <img
                src="./assets/img/beauty-parlour/icon/title_icon.png"
                alt=""
                className="title_icon"
              />
            </h2>
            <h6 className="desc">
              We’d love to hear from you! Reach out to Blessy Beauty Parlour for
              inquiries, appointments, or to learn more about our services, and
              let us help you shine.
            </h6>
          </div>
          <div className="au_card">
            <div className="left">
              <h3 className="inner_title">Address</h3>
              <h6 className="inner_desc">
                1A/5A, Padmawati Avenue, Kanipura Road, Near Petrol Pump,
                Ujjain, Madhya Pradesh - 456006, India
              </h6>
              <h6 className="inner_desc">blessyujjain@gmail.com</h6>
              <h6 className="inner_desc">+91-86673-59699</h6>
              <h6 className="inner_desc diff_links">
                <Link
                  target="_blank"
                  to="https://www.instagram.com/blessy_beauty_par.lour/profilecard/?igsh=MTh3cjZrZTc5YzE0Zg=="
                >
                  <img src="./assets/img/icons/footer_instagram.png" alt="" />
                </Link>
                <Link
                  target="_blank"
                  to="https://youtube.com/@blessybeautyparlour-w5b?si=SLeSWB_COE1xeaLn"
                >
                  <img src="./assets/img/icons/footer_youtube.png" alt="" />
                </Link>
                <Link
                  target="_blank"
                  to="https://wa.me/918667359699?text=Hello!%20Mujhe%20aapki%20service%20bhut%20acchi%20lagi.%20Aapke%20makeup%20bhut%20ache%20hain.%20Main%20bhi%20aapke%20yahaan%20makeup%20karwana%20chahti%20hoon.%20Plz%20meri%20appointment%20book%20karein."
                >
                  <img
                    src="./assets/img/icons/footer_whatsapp.png"
                    alt="WhatsApp"
                  />
                </Link>

                <Link
                  target="_blank"
                  to="https://maps.app.goo.gl/D8ZRwumh4XpcFqay8"
                >
                  <img src="./assets/img/icons/pin.png" alt="" />
                </Link>
              </h6>
            </div>
            <div className="right">
              <div style={{ width: "100%", height: "400px" }}>
                <iframe
                  title="Blessy Beauty Parlour Location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2593.010830737722!2d75.79829442294341!3d23.2040799281117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396375119b58cc0d%3A0x5fa2ca0db3b64835!2sBlessy%20Beauty%20Parlour!5e0!3m2!1sen!2sin!4v1728221967896!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BpContactUs;
