import React from 'react'

import BPAboutUs from '../component/BPAboutUs'
import BpContactUs from '../component/BpContactUs'
const BpAboutusPage = () => {
  return (
    <div className='aboutus_page'>
      <BPAboutUs/>
      <BpContactUs/>
    </div>
  )
}

export default BpAboutusPage
