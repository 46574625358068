import React from 'react';
import { Link } from 'react-router-dom';

const DdBy = () => {
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  return (
    <div className="ddby" style={{ textAlign: 'center', padding: '15px', fontSize: '16px', color: '#555', background:"#fff0f3" }}>
      <div>
        Copyright © {currentYear} BlessyBeautyParlour. <br></br> All Rights Reserved.
      </div>
      <div style={{ marginTop: '10px', fontStyle: 'italic' }}>
        Design and Developed by <br></br> Sanskar Solanki | <Link to="tel:+918770534650" style={{
            color:"#DB3781"
        }}>+91-87705-34650</Link>
      </div>
    </div>
  );
}

export default DdBy;
