import React from 'react'

import BpServices from "../component/BpServices";
import BpContactUs from "../component/BpContactUs";

const BpServicesPage = () => {
    const servicePageServices = [
        {
          title: "Bridal Makeup",
          description: "Let us create a flawless, radiant look for your special day with our bridal makeup services.",
          icon: "./assets/img/beauty-parlour/icon/make-up-black.png",
          link: "/gallery?category=makeup"
        },
        {
          title: "Facials & Skincare",
          description: "Revitalize your skin with our rejuvenating facials and advanced skincare treatments.",
          icon: "./assets/img/beauty-parlour/icon/mask-black.png",
          link: "/gallery?category=makeup"
        },
        {
          title: "Nail Art",
          description: "Add creativity and flair to your nails with our stunning and personalized nail art designs.",
          icon: "./assets/img/beauty-parlour/icon/manicure_black.png",
          link: "/gallery?category=nailart"
        },
        {
          title: "Waxing & Threading",
          description: "Get smooth, flawless skin with our expert waxing and threading services.",
          icon: "./assets/img/beauty-parlour/icon/waxing.png",
          link: "/gallery?category=makeup"
        },
        {
          title: "Hair Dressing",
          description: "Get styled to perfection with our expert hairdressing services, tailored for every occasion.",
          icon: "./assets/img/beauty-parlour/icon/head.png",
          link: "/gallery?category=hairdressing"
        },
        {
          title: "Special Makeup",
          description: "Look your absolute best for any occasion with our flawless and customized special event makeup services.",
          icon: "./assets/img/beauty-parlour/icon/makeup-kit-black.png",
          link: "/gallery?category=makeup"
        }
      ];
  return (
    <div className='services_page'>
      <BpServices services={servicePageServices} />
      <BpContactUs/>
    </div>
  )
}

export default BpServicesPage
