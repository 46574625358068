import React from "react";
import Slider from "react-slick"; // Import Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./BpTestimonial.scss";

const BpTestimonial = () => {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Enable looping
    speed: 500, // Transition speed in ms
    slidesToShow: 1, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Autoplay interval in ms
    pauseOnHover: false, // Pause autoplay on hover
    arrows: false, // Show next/prev arrows
  };

  return (
    <div
      className="bptestimonial sect_padding"
      id="testimonial"
      style={{
        backgroundImage:
          "url('./assets/img/beauty-parlour/testimonial-bg.png')",
      }}
    >
      <div className="container">
        <div className="about_us_inner">
          <div className="sect_header text-center">
            <h2 className="title">
              Customer Testimonials
              <img
                src="./assets/img/beauty-parlour/icon/title_icon.png"
                alt=""
                className="title_icon"
              />
            </h2>
            <h6 className="desc">
              Hear from our delighted clients who have experienced the magic of
              Blessy Beauty Parlour. Their stories of transformation and
              satisfaction reflect our commitment to excellence and customer
              care.
            </h6>
          </div>
          <div className="testimonial_card">
            <Slider {...settings}>
              <div className="tc_single text-center">
                <div className="t_pic">
                  <img src="./assets/img/beauty-parlour/r_client2.jpg" alt="" />
                </div>
                <p className="t_desc regular">
                  My experience at Blessy Beauty Parlour was nothing short of
                  amazing! The stylists understood my vision perfectly and
                  brought it to life. I left feeling beautiful and confident.
                  Highly recommend!
                </p>
                <h5 className="t_name ">Isha Solanki</h5>
                <h6 className="t_d regular">Satisfied Client</h6>
              </div>
              <div className="tc_single text-center">
                <div className="t_pic">
                  <img src="./assets/img/beauty-parlour/r_client3.jpg" alt="" />
                </div>
                <p className="t_desc regular">
                  I had a wonderful time at Blessy Beauty Parlour! The team was
                  incredibly professional and welcoming. They made my bridal
                  look absolutely stunning. Thank you for making my special day
                  even more memorable!
                </p>
                <h5 className="t_name ">Trisha Shrivas</h5>
                <h6 className="t_d regular">Satisfied Client</h6>
              </div>
              <div className="tc_single text-center">
                <div className="t_pic">
                  <img src="./assets/img/beauty-parlour/r_client1.jpeg" alt="" />
                </div>
                <p className="t_desc regular">
                  Blessy Beauty Parlour is my go-to place for all things beauty!
                  From haircuts to facials, I’ve always been thrilled with the
                  results. The staff is friendly, and the ambiance is so
                  relaxing!
                </p>
                <h5 className="t_name ">Pratibha Sen</h5>
                <h6 className="t_d regular">Satisfied Client</h6>
              </div>
              <div className="tc_single text-center">
                <div className="t_pic">
                  <img src="./assets/img/beauty-parlour/r_client4.jpg" alt="" />
                </div>
                <p className="t_desc regular">
                  I’ve tried many salons, but Blessy Beauty Parlour is truly the
                  best! The attention to detail and personalized service made
                  all the difference. I always leave feeling pampered and
                  rejuvenated!
                </p>
                <h5 className="t_name ">Surbhi Shrivas</h5>
                <h6 className="t_d regular">Satisfied Client</h6>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BpTestimonial;
