import React from 'react'

import BpContactUs from '../component/BpContactUs'

const BpContactUsPage = () => {
  return (
    <div className='contactus_page'>
      <BpContactUs/>
    </div>
  )
}

export default BpContactUsPage
