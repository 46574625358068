import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NineDots.scss';

const NineDots = ({ options = [] }) => {
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleClick = (whenClick) => {
    if (whenClick) {
      whenClick();  // Call the passed click handler (e.g. handleTabChange)
    }
    toggleOptions();  // Then toggle the menu visibility
  };

  return (
    <div className={`ninedots ${showOptions ? 'option_show' : ''}`}>
      <div className='open_div oc_icon_position' onClick={toggleOptions}>
        <div className="icon_div">
          <span className="material-symbols-outlined">apps</span>
        </div>
      </div>
      <div className='close_div oc_icon_position icon_with_text' onClick={toggleOptions}>
        <h6 className='df16 regular text_white'>Close</h6>
        <div className="icon_div">
          <span className="material-symbols-outlined">close</span>
        </div>
      </div>
      <div className="inner_options">
        <div className="io_menu">
          {options.map(({ link, text, icon, whenClick }, index) => (
            <Link
              key={index}
              className="iom_single icon_with_text df16 text_white regular"
              to={link || '#'}
              onClick={() => handleClick(whenClick)} // Handle both the click and toggle
            >
              <h6>{text}</h6>
              <div className="icon_div">
                <span className="material-symbols-outlined">{icon}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NineDots;
