import React from "react";

import "./BpAboutUs.scss";

const BPAboutUs = () => {
  return (
    <div className="about_us sect_padding" style={{
      backgroundImage:
        "url('./assets/img/beauty-parlour/about-bg-image.png')",
    }}
    id="about-us">
      <div className="container">
        <div className="about_us_inner">
          <div className="sect_header text-center">
            <h2 className="title">
              About Us
              <img
                src="./assets/img/beauty-parlour/icon/title_icon.png"
                alt=""
                className="title_icon"
              />
            </h2>
            <h6 className="desc">
            At Blessy Beauty Parlour, we believe that beauty is an experience, not just a service. Our passion for enhancing your natural beauty drives us to provide an exceptional salon experience tailored to your needs.
            </h6>
          </div>
          <div
            className="au_card"
            style={{
              backgroundImage:
                "url('./assets/img/beauty-parlour/about_bg.png')",
            }}
          >
            <div className="left">
              <h3 className="inner_title">Welcome to Blessy Beauty Parlour</h3>
              <h6 className="inner_desc">
              At Blessy Beauty Parlour, we believe that beauty is not just about looking good; it's about feeling good from within. Nestled in the heart of [Kanipura Road, Ujjain], our salon is a sanctuary where you can escape the hustle and bustle of everyday life and indulge in a transformative experience.
              </h6>
              <h6 className="inner_desc">
              At Blessy Beauty Parlour, we use only the finest products, ensuring your beauty and well-being are always our top priority. Our tranquil atmosphere is designed to provide a peaceful retreat where you can relax and unwind.
              </h6>
            </div>
            <div className="right"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BPAboutUs;
