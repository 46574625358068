import React from "react";
import Slider from "react-slick"; // Import Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./BpBanner.scss";
import { TypeAnimation } from "react-type-animation";

const BpBanner = () => {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Enable looping
    speed: 500, // Transition speed in ms
    slidesToShow: 1, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Autoplay interval in ms
    pauseOnHover: false, // Pause autoplay on hover
    arrows: false, // Show next/prev arrows
  };

  return (
    <div className="bp_banner" id="home">
      <div className="banner_slide">
        <div className="silde_single">
          <video
            src="./assets/video/bp/bannerone.mp4"
            autoPlay
            loop
            muted
            playsInline
            controls={false}
          />
        </div>
      </div>
      <div className="banner_content">
        <div className="bc_inner">
          <h1>
            Find your perfect{" "}
            <span>
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  "Bridal Makeup",
                  1500, // wait 1s before replacing "Mice" with "Hamsters"
                  "Hair Dressing",
                  1500,
                  "NailArt",
                  1500,
                  "Skincare",
                  1500,
                  "Threading",
                  1500,
                ]}
                wrapper="span"
                speed={10}
                repeat={Infinity}
              />
            </span>
          </h1>
          <h3>Look Beautiful, Feel Confident</h3>
        </div>
      </div>
    </div>
  );
};

export default BpBanner;
